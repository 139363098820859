import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CloseButton } from 'components/common';
import { useState } from 'react';
import { gamesService } from 'services';
import { formatBalance } from 'utils/common';
import { PopupInfoStats } from '.';

type PopupProps = PopupController & {};

const PopupListWithdraw = ({ onClose }: PopupProps) => {
  const { data } = useQuery(['gamesService.fetchStats'], () => gamesService.fetchStats());
  const { results } = data ?? {};

  const [openInfo, setOpenInfo] = useState(false);
  const [selectId, setSelectId] = useState('');

  return (
    <>
      <CloseButton onClick={onClose} />
      <DialogTitle>Lịch sử tham gia</DialogTitle>
      <DialogContent className='p-2'>
        <TableContainer component={Paper} variant='outlined' className='p-0'>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Kỳ</TableCell>
                <TableCell align='right'>Giao dịch</TableCell>
                <TableCell align='right'>Bonus</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results?.map((item, index) => (
                <TableRow
                  key={index}
                  className='cursor-pointer'
                  onClick={() => {
                    setOpenInfo(true);
                    setSelectId(item.session.id);
                  }}
                >
                  <TableCell>{item.session.incId}</TableCell>
                  <TableCell align='right'>{formatBalance(item.totalBet)}</TableCell>
                  <TableCell align='right'>{formatBalance(item.totalWinnings)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions className='flex justify-between items-center bg-primary-fill rounded-[8px]'>
        <div className='flex space-x-1'>
          <span>Giao dịch:</span>
          <span className='font-bold'>{data?.totalResults ?? 0}</span>
        </div>
        <div className='flex space-x-1'>
          <span>Tổng:</span>
          <span className='font-bold'>{formatBalance(data?.totalWinnings)}</span>
        </div>
      </DialogActions>

      <Dialog open={openInfo} onClose={() => setOpenInfo(false)} classes={{ paper: 'w-full' }}>
        <PopupInfoStats id={selectId} onClose={() => setOpenInfo(false)} />
      </Dialog>
    </>
  );
};

export default PopupListWithdraw;

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CloseButton } from 'components/common';
import { Fragment } from 'react';
import { gamesService } from 'services';
import { formatBalance, formatDate } from 'utils/common';

type PopupProps = PopupController & {};

const PopupListWithdraw = ({ onClose }: PopupProps) => {
  const { data } = useQuery(['gamesService.fetchWithdraws'], () => gamesService.fetchWithdraws());
  const { withdrawals: results } = data ?? {};

  return (
    <>
      <CloseButton onClick={onClose} />
      <DialogTitle>Lịch sử quy đổi</DialogTitle>
      <DialogContent className='p-2'>
        <TableContainer component={Paper} variant='outlined' className='p-0'>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Thời gian</TableCell>
                <TableCell align='right'>Số điểm</TableCell>
                <TableCell align='right'>Trạng thái</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {results?.map((item) => {
                const isNote = Boolean(item.note);
                return (
                  <Fragment key={item.id}>
                    <TableRow>
                      <TableCell className={isNote ? 'border-b-0' : ''}>{formatDate(item.createdAt)}</TableCell>
                      <TableCell className={isNote ? 'border-b-0' : ''} align='right'>
                        {formatBalance(item.amount)}
                      </TableCell>
                      <TableCell className={isNote ? 'border-b-0' : ''} align='right'>
                        {item.status === 'APPROVED' && <div className='text-success'>Đã quy đổi</div>}
                        {item.status === 'REJECTED' && <div className='text-error'>Lỗi</div>}
                        {item.status === 'PENDING' && <div className='text-info'>Chờ</div>}
                      </TableCell>
                    </TableRow>
                    {isNote && (
                      <TableRow className='mt-[-4px]'>
                        <TableCell className='pt-0' colSpan={3}>
                          Ghi chú: {item.note}
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions className='flex justify-between items-center bg-primary-fill rounded-[8px]'>
        <div className='flex space-x-1'>
          <span>Giao dịch:</span>
          <span className='font-bold'>{data?.totalWithdrawals ?? 0}</span>
        </div>
        <div className='flex space-x-1'>
          <span>Tổng:</span>
          <span className='font-bold'>{formatBalance(data?.totalWithdrawalAmount)}</span>
        </div>
      </DialogActions>
    </>
  );
};

export default PopupListWithdraw;
